<template>
  <section>
    <div class="custom-search d-flex align-items-center">
      <div
        class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
      >
        <template v-if="authUser.id == loanData?.created_by">
          <div
            v-if="
              loanData?.status != 'applied' &&
              loanData?.status != 'approved' &&
              loanData?.status != 'cancel' &&
              loanData?.status != 'final_apply' &&
              loanData?.status != 'rejected'
            "
          >
            <template>
              <b-button
                class="flex-shrink-0 mr-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-primary"
                v-on:click="loanReSubmitStatusUpdate()"
              >
                Re-Submit
              </b-button>
            </template>

            <template>
              <b-button
                class="flex-shrink-0 mr-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-warning"
                v-on:click="loanRejectStatusFinalApply()"
              >
                Final Apply
              </b-button>
            </template>

            <template>
              <b-button
                class="flex-shrink-0 mr-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-danger"
                v-on:click="loanCancelStatusUpdate()"
              >
                Cancel
              </b-button>
            </template>

            <template>
              <b-button
                class="flex-shrink-0 mr-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-primary"
                v-on:click="onShowPage(loanData)"
              >
                Review
              </b-button>
            </template>
          </div>
        </template>

        <template v-if="$permissionAbility(LOAN_APPROVER_ACCESS, permissions)">
          <div v-if="loanData?.status == 'final_apply'">
            <template v-if="loanApproveApiLoading">
              <b-button
                class="flex-shrink-0 ml-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-success"
                disabled
              >
                <b-spinner small />
                Loading ...
              </b-button>
            </template>
            <template v-else>
              <b-button
                class="flex-shrink-0 mr-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-success"
                v-on:click="loanApproveStatusUpdate()"
              >
                Approve
              </b-button>
            </template>

            <template>
              <b-button
                class="flex-shrink-0 mr-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-danger"
                v-on:click="loanRejectStatusUpdate()"
              >
                Reject
              </b-button>
            </template>
          </div>

          <div
            v-if="
              loanData?.status != 'approved' && loanData?.status != 'rejected'
            "
          >
            <template>
              <b-button
                class="flex-shrink-0 mr-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-primary"
                v-on:click="onShowPage(loanData)"
              >
                Review
              </b-button>
            </template>
          </div>
        </template>
      </div>
    </div>
    <b-row>
      <b-col lg="8" md="8">
        <b-row>
          <b-col lg="12" cols="12">
            <b-card>
              <b-row>
                <b-col cols="6">
                  <h5 class="text-capitalize">Principal Amount</h5>
                  <template>
                    <div>
                      <b-card-text>
                        <b
                          >{{ loanData?.principal_amount }}
                          {{ defaultCurrency }}</b
                        ></b-card-text
                      >
                    </div>
                  </template></b-col
                >
                <b-col cols="6" >
                  <h5 class="text-capitalize">Total Amount</h5>
                  <template>
                    <div>
                      <!-- <b-card-text> <b>{{ totalLoanAmountInValue() }} {{ defaultCurrency }}</b></b-card-text> -->
                      <b-card-text> 
                        <b v-if="totalInterests"> {{ loanData?.principal_amount + totalInterests}} {{ defaultCurrency }}</b>
                        <b v-else> {{ installmentSummary?.total_amount }} {{ defaultCurrency }}</b>
                      </b-card-text>
                    </div></template
                  >
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col cols="6">
                  <h5 class="text-capitalize">Paid Amount</h5>
                  <template>
                    <div>
                      <b-card-text>
                        <b
                          > {{ installmentSummary?.paid_amount }}
                          {{ defaultCurrency }}</b
                        ></b-card-text
                      >
                    </div>
                  </template></b-col
                >
                <b-col cols="6">
                  <h5 class="text-capitalize">Remaining Amount</h5>
                  <template>
                    <div>
                      <b-card-text> 
                        <b> {{ installmentSummary?.remaining_amount }} {{ defaultCurrency }}</b>
                      </b-card-text>
                    </div></template
                  >
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col cols="6">
                  <h5 class="text-capitalize">Installment Start Date</h5>
                  <template>
                    <div>
                      <b-card-text>
                        {{
                          formattedDate(loanData?.installment_start_date)
                        }}</b-card-text
                      >
                    </div>
                  </template></b-col
                >
                <b-col cols="6">
                  <h5 class="text-capitalize">Installment End Date</h5>
                  <template>
                    <div>
                      <b-card-text>
                        {{
                          formattedDate(loanData?.installment_end_date)
                        }}</b-card-text
                      >
                    </div></template
                  >
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col cols="6">
                  <h5 class="text-capitalize">Total Installments</h5>
                  <template>
                    <div>
                      <b-card-text>
                        {{ installmentSummary?.installments }}
                      </b-card-text>
                    </div>
                  </template></b-col
                >
                <b-col cols="6">
                  <h5 class="text-capitalize">Paid</h5>
                  <template>
                    <div>
                      <b-card-text>
                        {{ installmentSummary?.paid }}
                      </b-card-text>
                    </div></template
                  >
                </b-col>
                
              </b-row>

              <b-row class="mt-2">
                <b-col cols="6" >
                  <h5 class="text-capitalize">Remaining</h5>
                  <template>
                    <div>
                      <b-card-text>
                        {{ installmentSummary?.remaining }}
                      </b-card-text>
                    </div></template
                  >
                </b-col>
                <b-col cols="6">
                  <h5 class="text-capitalize">Total Interest Amount</h5>
                  <template>
                    <div>
                      <b-card-text
                        v-if="loanData?.loanPolicy?.data?.interest_rate"
                      >
                        <b>{{ totalInterests }} {{ defaultCurrency }}</b>
                      </b-card-text>

                      <b-card-text
                        v-else
                      >
                        <b>0 {{ defaultCurrency }}</b>
                      </b-card-text>
                    </div>
                  </template>
                </b-col>
                
              </b-row>

              <b-row class="mt-2">
                <b-col cols="6">
                  <h5 class="text-capitalize">Interest Rate</h5>
                  <template>
                    <div>
                      <b-card-text>
                        <b-badge
                          pill
                          variant="light-primary"
                          v-if="loanData?.loan_policy_id"
                          >{{
                            loanData?.loanPolicy?.data?.interest_rate
                          }}%</b-badge
                        >
                        <b-badge pill variant="light-danger" v-else
                          >Not Set Yet</b-badge
                        >
                      </b-card-text>
                    </div>
                  </template>
                </b-col>

                <b-col cols="6">
                  <h5 class="text-capitalize">Interest Policy</h5>
                  <template>
                    <div>
                      <b-card-text>
                        {{ loanData?.loanPolicy?.data?.title }}</b-card-text
                      >
                    </div>
                  </template>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col cols="6">
                  <h5 class="text-capitalize">Purpose</h5>
                  <template>
                    <div>
                      <b-card-text> {{ loanData?.purpose }}</b-card-text>
                    </div>
                  </template>
                </b-col>

                <b-col cols="6">
                  <h5 class="text-capitalize">Status</h5>
                  <template>
                    <div>
                      <b-card-text>
                        <b-badge
                          v-if="loanData?.status === 'applied'"
                          pill
                          variant="light-primary"
                        >
                          Applied
                        </b-badge>

                        <b-badge
                          v-if="loanData?.status === 're_submit'"
                          pill
                          variant="light-primary"
                        >
                          Re Submit
                        </b-badge>

                        <b-badge
                          v-if="loanData?.status === 'sent_back'"
                          pill
                          variant="light-warning"
                        >
                          Sent Back
                        </b-badge>

                        <b-badge
                          v-if="loanData?.status === 'final_apply'"
                          pill
                          variant="light-warning"
                        >
                          Final Apply
                        </b-badge>

                        <b-badge
                          v-if="loanData?.status === 'cancel'"
                          pill
                          variant="light-danger"
                        >
                          Cancel
                        </b-badge>

                        <b-badge
                          v-if="loanData?.status === 'rejected'"
                          pill
                          variant="light-danger"
                        >
                          Rejected
                        </b-badge>

                        <b-badge
                          v-if="loanData?.status === 'approved'"
                          pill
                          variant="light-success"
                        >
                          Approved
                        </b-badge>
                      </b-card-text>
                    </div>
                  </template>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col cols="12">
                  <h5 class="text-capitalize">Remarks</h5>
                  <template>
                    <div>
                      <b-card-text> {{ loanData?.remarks }}</b-card-text>
                    </div>
                  </template>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <!-- Loan Activity Log -->
      <b-col lg="4" md="4">
        <div style="max-height: 500px; overflow-y: auto">
          <b-card title="Loan Activity Log">
            <app-timeline>
              <template v-for="item in loanActivityLogsData">
                <!-- Loan Created activity log -->
                <template
                  v-if="
                    item?.log_name == 'loans' && item?.description == 'created'
                  "
                >
                  <app-timeline-item
                    :key="item?.id"
                    :title="`Loan Created by ${item?.properties?.attributes?.createdBy?.name}`"
                    :subtitle="formatDateTime(item?.created_at)"
                    :time="formatYear(item?.created_at)"
                    icon="InfoIcon"
                    variant="primary"
                  />
                </template>

                <!-- Loan Data activity log -->
                <template
                  v-else-if="
                    item?.log_name == 'loans' &&
                    item?.description == 'updated' &&
                    item?.properties?.old?.status ==
                      item?.properties?.attributes?.status
                  "
                >
                  <app-timeline-item
                    :key="item?.id"
                    icon="InfoIcon"
                    variant="info"
                  >
                    <div
                      class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                    >
                      <h6>
                        Loan Data Updated By
                        {{ item?.properties?.attributes?.updatedBy?.name }}
                      </h6>

                      <small class="text-muted">
                        Loan Data Update Report
                      </small>

                      <small class="text-muted">{{
                        formatYear(item?.created_at)
                      }}</small>
                      <span class="align-bottom">{{
                        formatDateTime(item?.created_at)
                      }}</span>

                      <br />

                      <b-button
                        v-b-toggle="`milestone-change-list-${item?.id}`"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        class="mt-1"
                      >
                        Show Changes
                      </b-button>
                      <b-collapse :id="`milestone-change-list-${item?.id}`">
                        <b-row class="custom-font">
                          <b-col cols="6" class="pr-1">
                            <b-list-group flush class="mt-1">
                              <b-list-group-item
                                class="d-flex justify-content-between align-items-center bg-transparent"
                              >
                                <span><b>Previous</b></span>
                              </b-list-group-item>

                              <b-list-group-item
                                class="d-flex justify-content-between align-items-center bg-transparent"
                              >
                                <span
                                  ><b>Principal Amount:</b>
                                  {{
                                    item?.properties?.old?.principal_amount
                                  }}</span
                                >
                              </b-list-group-item>

                              <b-list-group-item
                                class="d-flex justify-content-between align-items-center bg-transparent"
                              >
                                <span
                                  ><b>Installment Start Date:</b>
                                  {{
                                    formattedDate(
                                      item?.properties?.old
                                        ?.installment_start_date
                                    )
                                  }}</span
                                >
                              </b-list-group-item>

                              <b-list-group-item
                                class="d-flex justify-content-between align-items-center bg-transparent"
                              >
                                <span
                                  ><b>Installment End Date:</b>
                                  {{
                                    formattedDate(
                                      item?.properties?.old
                                        ?.installment_end_date
                                    )
                                  }}</span
                                >
                              </b-list-group-item>

                              <b-list-group-item
                                class="d-flex justify-content-between align-items-center bg-transparent"
                              >
                                <span
                                  ><b>Loan Policy:</b>
                                  {{
                                    item?.properties?.old?.loanPolicy?.title
                                  }}</span
                                >
                              </b-list-group-item>

                              <b-list-group-item
                                class="d-flex justify-content-between align-items-center bg-transparent"
                              >
                                <span
                                  ><b>Interest Rate:</b>
                                  {{
                                    item?.properties?.old?.loanPolicy
                                      ?.interest_rate
                                  }}
                                  %</span
                                >
                              </b-list-group-item>
                            </b-list-group>
                          </b-col>
                          <b-col cols="6">
                            <b-list-group flush class="mt-1">
                              <b-list-group-item
                                class="d-flex justify-content-between align-items-center bg-transparent"
                              >
                                <span><b>Updated</b></span>
                              </b-list-group-item>

                              <b-list-group-item
                                class="justify-content-between align-items-center bg-transparent"
                              >
                                <b>Principal Amount: </b>
                                <template
                                  v-if="
                                    item?.properties?.attributes
                                      ?.principal_amount !==
                                    item?.properties?.old?.principal_amount
                                  "
                                >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes
                                        ?.principal_amount
                                    }}</span
                                  ></template
                                ><template v-else>
                                  <span>
                                    {{
                                      item?.properties?.attributes
                                        ?.principal_amount
                                    }}
                                  </span>
                                </template>
                              </b-list-group-item>

                              <b-list-group-item
                                class="justify-content-between align-items-center bg-transparent"
                              >
                                <b>Installment Start Date:</b>
                                <template
                                  v-if="
                                    item?.properties?.attributes
                                      ?.installment_start_date !==
                                    item?.properties?.old
                                      ?.installment_start_date
                                  "
                                >
                                  <span class="text-danger">
                                    {{
                                      formattedDate(
                                        item?.properties?.attributes
                                          ?.installment_start_date
                                      )
                                    }}</span
                                  ></template
                                ><template v-else>
                                  <span>
                                    {{
                                      formattedDate(
                                        item?.properties?.attributes
                                          ?.installment_start_date
                                      )
                                    }}
                                  </span>
                                </template>
                              </b-list-group-item>

                              <b-list-group-item
                                class="justify-content-between align-items-center bg-transparent"
                              >
                                <b>Installment End Date:</b>
                                <template
                                  v-if="
                                    item?.properties?.attributes
                                      ?.installment_end_date !==
                                    item?.properties?.old?.installment_end_date
                                  "
                                >
                                  <span class="text-danger">
                                    {{
                                      formattedDate(
                                        item?.properties?.attributes
                                          ?.installment_end_date
                                      )
                                    }}</span
                                  ></template
                                ><template v-else>
                                  <span>
                                    {{
                                      formattedDate(
                                        item?.properties?.attributes
                                          ?.installment_end_date
                                      )
                                    }}
                                  </span>
                                </template>
                              </b-list-group-item>

                              <b-list-group-item
                                class="justify-content-between align-items-center bg-transparent"
                              >
                                <b>Loan Policy:</b>
                                <template
                                  v-if="
                                    item?.properties?.attributes?.loanPolicy
                                      ?.title !==
                                    item?.properties?.old?.loanPolicy?.title
                                  "
                                >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes?.loanPolicy
                                        ?.title
                                    }}</span
                                  ></template
                                ><template v-else>
                                  <span>
                                    {{
                                      item?.properties?.attributes?.loanPolicy
                                        ?.title
                                    }}
                                  </span>
                                </template>
                              </b-list-group-item>

                              <b-list-group-item
                                class="justify-content-between align-items-center bg-transparent"
                              >
                                <b>Interest Rate:</b>
                                <template
                                  v-if="
                                    item?.properties?.attributes?.loanPolicy
                                      ?.interest_rate !==
                                    item?.properties?.old?.loanPolicy
                                      ?.interest_rate
                                  "
                                >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes?.loanPolicy
                                        ?.interest_rate
                                    }}
                                    %
                                  </span></template
                                ><template v-else>
                                  <span>
                                    {{
                                      item?.properties?.attributes?.loanPolicy
                                        ?.interest_rate
                                    }}
                                    %
                                  </span>
                                </template>
                              </b-list-group-item>
                            </b-list-group>
                          </b-col>
                        </b-row>
                      </b-collapse>
                    </div>
                  </app-timeline-item>
                </template>

                <!-- Loan Status Updated activity log -->
                <template v-else>
                  <app-timeline-item
                    :key="item?.id"
                    icon="InfoIcon"
                    variant="warning"
                  >
                    <div
                      class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                    >
                      <h6>
                        Loan Status Updated By
                        {{ item?.properties?.attributes?.updatedBy?.name }}
                      </h6>

                      <small
                        class="text-muted"
                        v-if="item?.properties?.old?.status"
                      >
                        <h6>
                          Loan Status Updated
                          <b-badge variant="info">
                            {{
                              getLoanStatusName(item?.properties?.old?.status)
                            }}
                          </b-badge>
                          to
                          <b-badge variant="info">
                            {{
                              getLoanStatusName(
                                item?.properties?.attributes?.status
                              )
                            }}
                          </b-badge>
                        </h6>
                      </small>

                      <small class="text-muted" v-else>
                        <h6>
                          Loan created and status changed to
                          <b-badge variant="info" class="badge-glow">
                            {{
                              getLoanStatusName(
                                item?.properties?.attributes?.status
                              )
                            }}
                          </b-badge>
                        </h6>
                      </small>

                      <small class="text-muted">{{
                        formatYear(item?.created_at)
                      }}</small>
                      <span class="align-bottom">{{
                        formatDateTime(item?.created_at)
                      }}</span>
                    </div>
                  </app-timeline-item>
                </template>
              </template>
            </app-timeline>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <div>
      <b-modal
        id="modal-loan-form"
        centered
        title=" Edit Loan Details "
        hide-footer
        @hidden="hiddenModal"
        no-close-on-backdrop
        size="lg"
      >
        <validation-observer ref="loanFormValidation">
          <b-form v-on:submit.prevent="validationForm">
            <b-row>
              <b-col class="mt-1" xs="12" lg="12" md="12">
                <!-- principal amount -->
                <b-form-group
                  label="Principal Amount"
                  label-for="principal_amount"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="principal amount"
                    vid="principal_amount"
                    rules="required"
                  >
                    <b-form-input
                      id="principal_amount"
                      type="number"
                      v-model="principalAmount"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Principal Amount"
                      :disabled="loanApplierFormFieldShowStatus"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col class="mt-1" xs="12" lg="12" md="12">
                <!-- Purpose -->
                <b-form-group
                  label="Purpose"
                  label-for="purpose"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Purpose"
                    vid="purpose"
                    rules="required"
                  >
                    <b-form-textarea
                      id="purpose"
                      type="text"
                      v-model="purposeText"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Purpose"
                      rows="3"
                      :disabled="loanApplierFormFieldShowStatus"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col class="mt-1" xs="6" lg="6" md="6">
                <!-- installment_start_date -->
                <b-form-group
                  label="Installment Start Date"
                  label-for="installment_start_date"
                  class="required-label"
                >
                  <ValidationProvider
                    name="installment start date"
                    v-slot="{ errors }"
                    vid="installment_start_date"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="installment_start_date"
                      v-model="installmentStartDate"
                      :state="errors.length > 0 ? false : null"
                      locale="en-US"
                      today-button
                      close-button
                      reset-button
                      class="custom-font"
                      @context="onContextLoanDate"
                      :disabled="loanApplierFormFieldShowStatus"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col class="mt-1" xs="6" lg="6" md="6">
                <!-- installment_end_date -->
                <b-form-group
                  label="Installment End Date"
                  label-for="installment_end_date"
                  class="required-label"
                >
                  <ValidationProvider
                    name="installment end date"
                    v-slot="{ errors }"
                    vid="installment_end_date"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="installment_end_date"
                      v-model="installmentEndDate"
                      :state="errors.length > 0 ? false : null"
                      locale="en-US"
                      today-button
                      close-button
                      reset-button
                      class="custom-font"
                      @context="onContextLoanDate"
                      :disabled="loanApplierFormFieldShowStatus"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col class="mt-1" xs="12" lg="12" md="12">
                <!-- Loan Policy Assign -->
                <b-form-group
                  label="Loan Policies"
                  label-for="loan_policy_id"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="loan policies"
                    vid="loan_policy_id"
                    rules="required"
                  >
                    <v-select
                      id="loan_policy_id"
                      v-model="loanPolicyId"
                      :options="filterLoanPolicyIdOption"
                      :reduce="(option) => option.id"
                      label="name"
                      placeholder="Choose Here"
                      :disabled="loanApproverFormFieldShowStatus"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col class="mt-1" xs="12" lg="12" md="12">
                <!-- Remarks -->
                <b-form-group
                  label="Remarks"
                  label-for="remarks"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Remarks"
                    vid="remarks"
                  >
                    <b-form-textarea
                      id="remarks"
                      type="text"
                      v-model="remarksText"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Remarks"
                      rows="3"
                      :disabled="loanApproverFormFieldShowStatus"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- loading button -->
            <template v-if="isLoading">
              <b-button class="float-right" variant="primary" disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <!-- submit button -->
            <template v-else>
              <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
              >
                Submit
              </b-button>
            </template>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>

    <div>
      <b-modal
        id="modal-extra-amount-form"
        centered
        title=" Add Extra Amount In Installment "
        hide-footer
        @hidden="hiddenExtraAmountModal"
        no-close-on-backdrop
        size="lg"
      >
        <validation-observer ref="loanExtraAmountFormValidation">
          <b-form v-on:submit.prevent="validationExtraAmountForm">
            <b-row>
              <b-col class="mt-1" xs="6" lg="6" md="6">
              <!-- name -->
              <b-form-group label="Title " label-for="title" class="required-label">
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  vid="title"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="title"
                    type="text"
                    v-model="extraAmountTitle"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- From account -->
            <b-col class="mt-1" xs="6" lg="6" md="6">
              <b-form-group label="Account " label-for="account" class="required-label">
                <ValidationProvider
                  name="account"
                  v-slot="{ errors }"
                  vid="account_id"
                  rules="required"
                >
                  <!--  -->
                  <v-select
                    id="account"
                    placeholder="Choose an Account"
                    v-model="selectAccountId"
                    :options="accountsOption"
                    :reduce="(option) => option.id"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1" xs="12" lg="12" md="12">
                <!-- Installment Number -->
                <b-form-group
                  label="With which installment you want to add the extra amount"
                  label-for="installment_number"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Installment Number"
                    vid="installment_number"
                    rules="required"
                  >
                    <v-select
                      id="installment_number"
                      placeholder="Choose an installment number"
                      v-model="installmentNumber"
                      :options="installmentNumberOption"
                      :reduce="(option) => option.id"
                      label="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col class="mt-1" xs="12" lg="12" md="12">
                <!-- Extra amount -->
                <b-form-group
                  label="Extra Amount"
                  label-for="extra_amount"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Extra Amount"
                    vid="extra_amount"
                    rules="required"
                  >
                    <b-form-input
                      id="extra_amount"
                      type="number"
                      v-model="extraAmount"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Extra Amount"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>


                <!-- details -->
                <b-col class="mt-1" xs="12" lg="12" md="12">
                <b-form-group label="Remarks " label-for="Remarks" class="required-label">
                  <validation-provider
                    #default="{ errors }"
                    name="remarks"
                    vid="remarks"
                    rules="required"
                  >
                    <b-form-textarea
                      id="remarks"
                      v-model="extraRemarks"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Add Remarks"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                </b-col>

              
            </b-row>

            <!-- loading button -->
            <template v-if="isExtraAmountSubmitLoading">
              <b-button class="float-right" variant="primary" disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <!-- submit button -->
            <template v-else>
              <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
              >
                Re-Generate Installments
              </b-button>
            </template>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>

  <b-card>


    <b-row class="mt-2">
      <b-col cols="12">
        <b-row>
          <b-col lg="8" md="8">
            <h5 class="text-capitalize">Amortization Table</h5>
          </b-col>

          <b-col lg="4" md="4" v-if="$permissionAbility(LOAN_APPROVER_ACCESS, permissions) && loanData?.status === 'approved'">
            <b-button
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-primary"
              v-on:click="onShowExtraAmountPage(loanData)"
            >
              Add Extra Amount
            </b-button>
          </b-col>
        </b-row>

        <b-table
          :items="installmentsBreakdown"
          :fields="tableFields"
          class="mt-1"
        >
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(date)="data">
            {{ data.item.date }}
          </template>
          <template #cell(monthly_payment)="data">
            <FormatCurrency
              :currency="defaultCurrency"
              :amount="data.item.monthly_payment"
            />
          </template>
          <template #cell(principal_amount)="data">
            <FormatCurrency
              :currency="defaultCurrency"
              :amount="data.item.principal_amount"
            />
          </template>
          <template #cell(interest_amount)="data">
            <FormatCurrency
              :currency="defaultCurrency"
              :amount="data.item.interest_amount"
            />
          </template>
          <template #cell(remaining_principal)="data">
            <FormatCurrency
              :currency="defaultCurrency"
              :amount="data.item.remaining_principal"
            />
          </template>
          <template #cell(is_paid)="data">
            <div v-if="data.item?.is_paid == 1">
              Paid
            </div> 
            <div v-else>
              Not Paid
            </div> 
          </template>

        </b-table>
      </b-col>
    </b-row>

  </b-card>

  </section>
</template>




<script>
import {
  BLink,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BButton,
  BForm,
  BModal,
  BCol,
  BRow,
  BCardText,
  BFormTextarea,
  BSpinner,
  BAlert,
  VBTooltip,
  BFormDatepicker,
  BCollapse,
  BListGroup,
  BListGroupItem,
  VBToggle,
  BTable,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { heightFade } from "@core/directives/animations";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required, max, size, ext, max_value, min_value } from "@validations";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { loanStatusConstants } from "@/helpers/constant/loanStatusConstant";
import { LOAN_APPROVER_ACCESS } from "@/helpers/permissionsConstant";
import FormatCurrency from "@/layouts/components/FormatCurrency.vue";
export default {
  name: "LoanDetailsView",
  components: {
    FormatCurrency,
    BTable,
    BLink,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCol,
    BRow,
    BCardText,
    BSpinner,
    BFormTextarea,
    flatPickr,
    BAlert,
    BFormDatepicker,
    AppTimeline,
    AppTimelineItem,
    BCollapse,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    heightFade,
  },
  data() {
    return {
      LOAN_APPROVER_ACCESS,
      modelType: "",
      name: "",
      defaultCurrency: "",

      // loan details
      accountsOption: [],
      installmentNumberOption: [],
      extraAmountTitle: "",
      selectAccountId: "",
      extraRemarks: "",

      loanData: "",
      filterLoanPolicyIdOption: [],
      installmentStart: "",
      installmentEnd: "",
      loanStatusConstants,
      loanPolicyId: "",
      isLoading: false,
      isExtraAmountSubmitLoading: false,
      principalAmount: "",
      extraAmount: "",
      installmentNumber: "",
      totalLoanAmount: "",
      installmentEndDate: "",
      installmentStartDate: "",
      purposeText: "",
      remarksText: "",
      installments: [],
      installmentsBreakdown: [],
      loanActivityLogsData: "",
      loanApplierFormFieldShowStatus: true,
      loanApproverFormFieldShowStatus: false,
      totalInterests: "",
      loanApproveApiLoading: false,
      installmentSummary: "",
      tableFields: [
        { key: "#", label: "#" },
        { key: "date", label: "Date" },
        { key: "beginning_balance", label: "Beginning Balance" },
        { key: "scheduled_payment", label: "Scheduled Payment" },
        { key: "extra_amount", label: "Extra Amount" },
        { key: "total_payment", label: "Total Payment" },
        { key: "principal_amount", label: "Principal Amount" },
        { key: "interest_amount", label: "Interest Amount" },
        { key: "ending_balance", label: "Ending Balance" },
        { key: "is_paid", label: "Paid Status" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
      authUser: "userModule/getUser",
    }),
  },

  async created() {
    try {
      const [getSetting, getCurrencies] = await Promise.all([
        this.getSetting({
          select: "currency_id",
        }),
        this.getCurrencies(),
      ]);

      // getSetting
      const currencyId = getSetting?.data?.data?.currency_id;

      // getCurrencies
      const currencyData = getCurrencies?.data?.data?.filter((item) => {
        return item?.id === currencyId;
      });

      this.defaultCurrency = currencyData?.[0]?.currency;

      this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    async getSetting(params) {
      return await this.$api.get("/api/settings", {
        params: {
          select: params.select,
        },
      });
    },
    async getCurrencies() {
      return await this.$api.get("/api/currencies/all");
    },
    formatDateTime(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
    },

    formatYear(value) {
      if (value) {
        return this.$moment(value).format("YYYY");
      }
    },

    resetInstallments() {
      this.installments = [];
    },

    onContextLoanDate(ctx) {
      this.purchaseDate = ctx.selectedYMD;
    },

    showModal() {
      this.$bvModal.show("modal-loan-form");

      // only applier can view
      if (this.authUser.id == this.loanData?.created_by) {
        this.loanApplierFormFieldShowStatus = false;
        this.loanApproverFormFieldShowStatus = true;
      } else {
        this.remarksText = "";
      }
    },

    showExtraAmountModal() {
      this.$bvModal.show("modal-extra-amount-form");
    },

    hiddenModal() {
      this.$bvModal.hide("modal-loan-form");
      this.resetModal();
    },

    hiddenExtraAmountModal() {
      this.$bvModal.hide("modal-extra-amount-form");
      this.resetExtraAmountModal();
    },

    resetExtraAmountModal(){
      this.extraAmountTitle = "";
      this.selectAccountId = "";
      this.installmentNumber = "";
      this.extraAmount = "";
      this.extraRemarks = "";
    },

    resetModal() {
      this.loanPolicyId = "";
      this.principalAmount = "";
      this.totalLoanAmount = "";
      this.installmentEndDate = "";
      this.installmentStartDate = "";
      this.purposeText = "";
      this.remarksText = "";
    },

    onShowPage(value) {
      this.principalAmount = value.principal_amount;
      this.totalLoanAmount = value.total_amount;
      this.installmentEndDate = value.installment_end_date;
      this.installmentStartDate = value.installment_start_date;
      this.purposeText = value.purpose;
      this.loanPolicyId = value.loan_policy_id;
      this.remarksText = value.remarks;

      this.showModal();
    },

    onShowExtraAmountPage(value) {
      const loanId = value.id;
      this.showExtraAmountModal();
    },

    async loanReSubmitStatusUpdate() {
      await this.updateLoanStatusReSubmit();
      await this.loadItems();
    },

    async loanCancelStatusUpdate() {
      await this.updateLoanStatusCancel();
      await this.loadItems();
    },

    async loanRejectStatusUpdate() {
      await this.updateLoanStatusReject();
      await this.loadItems();
    },

    async loanRejectStatusFinalApply() {
      await this.updateLoanStatusFinalApply();
      await this.loadItems();
    },

    async loanApproveStatusUpdate() {
      this.loanApproveApiLoading = true;
      await this.updateLoanStatusApproveApply();
      this.loanApproveApiLoading = false;
      await this.loadItems();
    },

    async updateLoanStatusApproveApply() {
      return await this.$api.get(`api/loan/${this.loanData?.id}/approve`);
    },

    async updateLoanStatusFinalApply() {
      return await this.$api.put(`api/loan/${this.loanData?.id}/final-apply`);
    },

    async updateLoanStatusReject() {
      return await this.$api.put(`api/loan/${this.loanData?.id}/reject`);
    },

    async updateLoanStatusCancel() {
      return await this.$api.put(`api/loan/${this.loanData?.id}/cancel`);
    },

    async updateLoanStatusReSubmit() {
      return await this.$api.put(`api/loan/${this.loanData?.id}/resubmit`);
    },

    async getLoanItems() {
      return await this.$api.get(
        `api/loans/${this.$route.params.id}?include=loanPolicy`
      );
    },

    async getLoanActivityLog() {
      return await this.$api.get(
        `api/loan/${this.$route.params.id}/activity-log`
      );
    },

    async getLoanPolicyItems() {
      return await this.$api.get("api/loan-policies");
    },

    async getLoanInstallmentBreakdown() {
      return await this.$api.get(
        `api/loan/${this.$route.params.id}/installments`
      );
    },

    async getLoanInstallmentPaid() {
      return await this.$api.get(
        `api/loan/${this.$route.params.id}/installment/is-paid`
      );
    },

    async getLoanInstallmentApproved() {
      return await this.$api.get(
        `api/loan/${this.$route.params.id}/installments/approved`
      );
    },

    async getActiveAccounts() {
      return await this.$api.get("api/accounts/active/all");
    },

    async loadItems() {
      try {
        const [loanItems, loanPolicyItems, loanActivityLogs, loanInstallmentsPaidItems, loanInstallmentsApprovedItems, getActiveAccounts] =
          await Promise.all([
            this.getLoanItems(),
            this.getLoanPolicyItems(),
            this.getLoanActivityLog(),
            this.getLoanInstallmentPaid(),
            this.getLoanInstallmentApproved(),
            this.getActiveAccounts(),
          ]);

        this.loanData = loanItems?.data?.data;

        this.installmentSummary = loanInstallmentsPaidItems?.data?.data?.original;


        // Filter Loan Policy
        this.filterLoanPolicyIdOption = (loanPolicyItems?.data?.data || []).map(
          (item) => {
            let name = item.title;
            return {
              name,
              id: item.id,
              interest_rate: item.interest_rate,
            };
          }
        );

        //active accounts
      this.accountsOption = (getActiveAccounts?.data?.data || []).map(
        (item) => {
          const name = `${item?.name} (${item?.currency} ${item?.balance})`;
          const id = item?.id;

          return {
            name,
            id,
          };
        }
      );

        if(this.loanData?.loan_policy_id){
          //call installment calculation function
          const loanInstallmentBreakdown =
            await this.getLoanInstallmentBreakdown();

          if (this.loanData?.status != "approved") {
            this.installmentsBreakdown =
              loanInstallmentBreakdown?.data?.data?.original?.installments;
          }else{
            this.installmentsBreakdown = loanInstallmentsApprovedItems?.data?.loan_installment_details;
          }
          
          this.totalInterests = loanInstallmentBreakdown?.data?.data?.original?.total_interest;
        }

          
        const count = (this.installmentsBreakdown || []).length;
        // Create installmentNumberOption array
        this.installmentNumberOption = Array.from({ length: count }, (_, index) => ({
          name: index + 1,
          id: index + 1,
        }));

        // activity log
        this.loanActivityLogsData = loanActivityLogs?.data?.data;

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    validationForm: async function () {
      this.$refs.loanFormValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;
            await this.$api.put(
              `/api/loans/${this.loanData?.id}`,

              {
                loan_policy_id: this.loanPolicyId,
                principal_amount: this.principalAmount,
                installment_start_date: this.installmentStartDate,
                installment_end_date: this.installmentEndDate,
                purpose: this.purposeText,
                remarks: this.remarksText,
              }
            );
            this.isLoading = false;
            this.hiddenModal();
            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Loan Data Successfully Updated",
              },
            });
          } catch (error) {
            this.isLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.loanFormValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },

    validationExtraAmountForm: async function () {
      this.$refs.loanExtraAmountFormValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.isExtraAmountSubmitLoading = true;
            await this.$api.put(
              `/api/loans/${this.loanData?.id}/re-generate-installments`,

              {
                extra_amount_title: this.extraAmountTitle,
                account_id: this.selectAccountId,
                installment_number: this.installmentNumber,
                extra_amount: this.extraAmount,
                extra_remarks: this.extraRemarks,
              }
            );
            this.isExtraAmountSubmitLoading = false;
            this.hiddenExtraAmountModal();
            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Installments Re-Generated Successfully",
              },
            });
          } catch (error) {
            this.isExtraAmountSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.loanFormValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },

    formattedDate(value) {
      const date = new Date(value);

      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear();

      return `${month} ${year}`;
    },

    getLoanStatusName(status) {
      const statusObj = this.loanStatusConstants.find(
        (item) => item.value === status
      );
      return statusObj ? statusObj.name : status;
    },
  },
};
</script>

    <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.table-custom-style {
  font-size: 14px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}
.table-cell-margin {
  margin: 2px 0px;
}
.custom-font {
  font-size: 12px;
}
</style>
